.container {
	padding: 1.5rem;
	background-color: white;
	height: 100vh;
}

.body {
	margin: 2rem 0;
	position: relative;
}
.header {
	display: flex;
	justify-content: space-between;
}

.TextArea textarea {
	height: 130px !important;
}

.footer {
	width: 90%;
	position: absolute;
	bottom: 2rem;
	margin-bottom: 1rem;
}

.addresses {
	margin-top: 32px !important;
}
.address {
	padding: 24px;
	margin: 0 0 24px 0;
}

.selectedAddress {
	background: #e8f3ef;
	border-radius: 8px;
}

.mainAddress {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.addressContainer {
	padding: 28px 28px 0 28px;
	display: flex;
	flex-direction: column !important;
	justify-content: space-between !important;
	height: 100vh;
	background: white;
}
.notSelectedAdd {
	border: 1px solid #d1d6dd;
	border-radius: 8px;
}

.noAddress {
	display: flex;
	margin-top: 30%;
	justify-content: center;
	align-items: center;
}
.sampleImage {
	display: flex;
	justify-content: center;
}

.centerAlign {
	display: flex;
	margin-top: 24px;
	justify-content: center;
}

.footerAdd {
	display: flex;
	align-items: center;
	padding: 0px !important;
	margin: 0px !important;
}

.addressBody {
	height: calc(100vh - 200px);
	overflow: auto;
}

.addAddressContainer {
	display: flex;
	flex-direction: column !important;
	padding: 2rem;
	height: 100vh;
	background: white;
	justify-content: space-between !important;
}
