.modal {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px !important;
}

.grid {
	background-color: white;
	border-radius: 8px !important;
	padding: 2rem;
	text-align: center;
	width: 27.75rem;
	min-height: 26.25rem;
}

.inputField1 {
	padding-right: 0.5rem;
}
.inputField2 {
	padding-left: 0.5rem;
}

.bordered {
	border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
	padding-bottom: 1.2rem;
}
.displayFlex {
	display: flex !important;
	margin: 0.7rem 0;
	width: 100%;
	justify-content: space-between !important;
	align-items: center !important;
}
