.modal {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px !important;
}

.grid {
	background-color: white;
	border-radius: 8px !important;
	text-align: center;
	width: 444px;
	min-height: 420px;
	max-height: 80vh;
	overflow: auto;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.5rem;
	padding-bottom: 1rem;
}

.userSelectTile {
	display: flex;
	justify-content: space-between;
	height: 56px;
	align-items: center;
	padding: 0 1.5rem;
	cursor: pointer;
}
.grid1 {
	display: flex;
	align-items: center;
	text-align: left;
}

.footer {
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;
	padding: 0 1.5rem 28px 1.5rem;
}

.userTileGrid {
	min-height: 230px;
	border-radius: 20px;
	max-height: 50vh;
	align-items: center;
	display: flex;
	width: 100%;
	overflow: auto;
}

.userTileGrid :hover {
	background: #fbf9f9;
}
