.container {
	display: flex !important;
	min-width: 100%;
	justify-content: space-between;
	align-items: flex-start !important;
	align-items: center;
	margin: 2rem 0;
	padding-right: 1.5rem;
}
.iconAction {
	display: flex;
}
.image {
	display: flex;
	margin-left: 1.5rem !important;
	align-items: flex-start;
}
.attributes {
	margin: 5px 12px !important;
	max-width: 15.62rem;
}
.actionsMid {
	display: flex !important;
	flex-direction: column !important;
	justify-content: center;
	align-items: center;
}
.actionsRight {
	display: flex;
	min-width: 10.37rem;
	flex-direction: column !important;
	align-items: center;
	text-align: center;
	margin-left: 53px !important;
}

.title {
	font-weight: bold;
}

.imageCls {
	height: 72px;
	width: 72px;
	margin-top: 0.5rem;
	cursor: pointer;
	border-radius: 6px;
}

.btnClass {
	padding: 2rem;
	width: 9.18rem;
}

.staticQuntity {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.staticQuntityGrid {
	display: flex;
	flex-direction: column !important;
	align-items: flex-end !important;
	width: 100%;
}

.staticQuntity {
	margin-top: 1rem;
}

.imageAndAttributes {
	display: flex;
}

.btnTrash {
	border: none;
	background-color: transparent;
	margin: 1rem 0.9rem;
	cursor: pointer;
	height: 22px !important;
	width: 22px !important;
	color: #4f555e;
}

.rightControls {
	display: flex;
	margin-top: 0.5rem !important;
	align-items: flex-start;
	justify-content: space-between;
}

.overFlowText {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.imageAndAction {
	display: flex;
}

.menu {
	/* background: red; */
}

.menu > div {
	box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
}

@media screen and (max-width: 1300px) {
	.attributes {
		margin: 0 12px !important;
		max-width: 8.12rem;
	}
	.actionsRight {
		display: flex;
		min-width: 8.12rem;
		margin-left: 30px !important;
	}
}

@media screen and (max-width: 1100px) {
	.attributes {
		max-width: 6.25rem;
	}
	.btnClass {
		padding: 1rem;
		width: 8.125rem;
		font-size: 12px;
	}
	.actionsRight {
		display: flex;
		min-width: 6.25rem;
		margin-left: 20px !important;
	}
}

@media screen and (max-width: 950px) {
	.attributes {
		margin: 0px !important;
	}
	.imageAndAttributes {
		flex-direction: column !important;
	}
}
