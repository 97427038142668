.body {
	padding: 24px;
	height: 100vh;
	background: white;
}
.content {
	height: calc(100vh - 188px);
}
.header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	height: 64px;
}
.footer {
	height: 94px;
	display: flex;
	align-items: center;
}
.card {
	padding: 2rem;
	margin: 1rem 0;
	background: white;
	border-radius: 12px;
}
.cardTitle {
	justify-content: space-between;
	display: flex;
}

.content textarea {
	height: 130px !important;
}
