.container {
	padding: 1.5rem;
	display: block !important;
	background: white;
	height: 100vh;
}
.top {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.evenMargin {
	margin-top: 1rem;
}

.contactFields {
	margin-top: 2rem;
}

.body {
	height: calc(100% - 50px);
	overflow: auto;
}

.footer {
	height: 50px;
	margin-top: 0.7rem;
	display: flex;
	align-items: center;
}

.bodyFields {
	margin: 2rem 0;
}
