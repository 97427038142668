.productCell {
	display: flex;
	align-items: center;
	height: 100%;
}

.description {
	margin-left: 8px;
}

.headerClass {
	height: 100%;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	background: #f0f6ff;
	color: rgba(0, 0, 0, 0.6);
}

.imageCls {
	border-radius: 4px;
}

.bulkEditBody {
	width: 51.87rem;
	background: white;
	margin: 0px auto;
	padding-top: 2.4rem;
	margin-top: 2rem;
	border-radius: 20px;
}

.BulkEditContainer {
	width: 45.82rem;
	margin-top: 2rem;
	margin: 0px auto;
}
