.ReviewCartBody {
	margin: 1rem 0;
	background: white;
	border-radius: 12px;
	padding: 1rem 1rem 0rem 1rem;
	height: auto;
	overflow: auto;
}

.cartRight {
	min-height: calc(100vh - 17rem);
	margin: 1rem 0 1rem 1rem;
	padding: 2rem;
	border-radius: 12px;
	display: flex;
	flex-direction: column !important;
	justify-content: space-between !important;
	background: white;
	overflow: auto;
}

.products {
	background: white;
}

.card {
	display: flex;
	padding: 2rem;
	margin: 1rem 0;
	background: white;
	border-radius: 12px;
	justify-content: space-between;
}

.dashed {
	margin-top: 1rem;
	border-top: 1px dashed rgba(0, 0, 0, 0.12);
}

.spaceBetween {
	display: flex;
	margin: 0.5rem 0 !important;
	align-items: center;
	justify-content: space-between;
}
.spaceBetween2 {
	display: flex;
	margin: 0.5rem 0 !important;
	align-items: center;
}

.Input {
	margin: 0.5rem 0 !important;
}

.notesContainer {
	margin-top: 0.5rem !important;
}
.note {
}

.noteCheckbox {
	display: flex;
	margin-top: 1rem;
	align-items: center;
	justify-content: flex-start;
}

.noteCheckbox > span {
	padding: 0px !important;
}

.customerInfoGrid1 {
	display: flex;
	margin-top: 1rem;
}
.customerInfoGrid2 {
}

.customerInfoGrid3 {
	text-align: right;
}

.addressTitle {
	width: 170px;
}
.address {
	width: calc(100% - 300px);
	color: grey;
}

.addressContainer {
	margin-top: 1rem;
	padding-top: 2rem;
	border-top: 1px dashed rgba(0, 0, 0, 0.12);
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.actionBtn {
	width: 130px;
	text-align: right;
}

.body {
	width: 66.6%;
}
.right {
	width: 33.3%;
}

.showSmall {
	display: none;
}

.smallDeviceBtn {
	display: none !important;
	width: 25rem;
	margin: 0px auto;
}

@media screen and (max-width: 900px) {
	.right {
		display: none;
	}
	.body {
		width: 100%;
	}
	.smallDeviceBtn {
		display: block !important;
	}
	.showSmall {
		display: flex;
		width: 100%;
		justify-content: center;
		position: fixed;
		bottom: 2rem;
		left: 50%;
		transform: translateX(-50%);
	}
	.bottomGrid {
		position: fixed;
		bottom: 2rem;
		left: 50%;
		transform: translateX(-50%);
	}
}
