.cartRight {
	min-height: 53rem;
	margin: 1rem 0 1rem 1rem;
	padding: 2rem;
	border-radius: 20px;
	display: flex;
	flex-direction: column !important;
	justify-content: space-between !important;
	background: white;
}

.cartRightVisible {
	min-height: 100vh;
	padding: 2rem;
	margin: 0px;
	display: flex;
	flex-direction: column !important;
	justify-content: space-between !important;
	background: white;
}

.border {
	border-bottom: 1px dashed #b5bbc4;
	padding-bottom: 1rem;
}

.discountSection {
	margin-top: 2rem;
}

.taxSection {
	margin-top: 1.5rem;
}
.billingSummary {
	margin: 1rem 0;
}

.spaceBtwFlex {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.actionBtns {
	display: flex;
	justify-content: space-between;
}

.nameAndMenu {
	display: flex;
	justify-content: space-between;
}

.menuItem {
	display: flex;
	align-items: center;
}

.addDiscountBtn {
	display: flex;
	align-items: center;
	/* margin-top: 2rem; */
	justify-content: space-between;
}

.billingSummaryText {
	display: flex;
	justify-content: center;
	margin-bottom: 1rem;
}

.billingText {
	display: flex;
	width: 100%;
}
.billingText:after {
	border-bottom: 1px dotted black;
	content: '';
	margin-left: 4px;
	height: 50%;
	flex: 1;
}
.billingText::before {
	border-bottom: 1px dashed #b5bbc4;
	content: '';
	height: 50%;
	margin-right: 4px;
	flex: 1;
}

.margin1rem {
	margin-top: 0.7rem;
}

.logoAndUserDesciption {
	display: flex;
}

.btnClass {
	width: 100%;
}

@media screen and (max-width: 900px) {
	.cartRight {
		display: none;
	}
}

.backBtn {
	margin-bottom: 1rem;
}

.menuGrid :global(.MuiButton-root) {
	margin-right: -18px;
}

.menuGrid :global(.MuiButton-root) :hover {
	background: transparent !important;
}

.menuGrid :global(.MuiButton-root) :active {
	background: transparent !important;
}
