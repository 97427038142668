.quickSettingsBody {
	display: flex;
	align-items: center;
	background: #ffffff;
	height: calc(100vh - 100px);
	justify-content: space-between;
}

.header {
	position: sticky;
	top: 0;
	z-index: 2;
	padding: 1.2rem 1.75rem;
	background: white;
	height: 86px;
}

.headerSubheading {
	margin-top: 7px;
}

.applyBtn {
	width: 100%;
}

.commonMargin1rem {
	margin-bottom: 1rem;
}
.commonMargin2rem {
	margin-bottom: 2rem;
}

.applyGrid {
	display: flex;
	align-items: center;
	padding: 0 2rem;
	background: white;
	position: sticky;
	bottom: 0;
	height: 104px;
}

.body {
	height: calc(100vh - 86px - 104px);
	padding-top: 2rem !important;
	overflow: auto;
}
