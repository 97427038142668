.body {
	margin: 1rem 0;
	background: white;
	border-radius: 20px;
	position: relative;
	padding: 2rem;
	overflow: auto;
}

.cartRight {
	min-height: 34rem;
	margin: 1rem 0 1rem 1rem;
	padding: 2rem;
	border-radius: 20px;
	display: flex;
	flex-direction: column !important;
	justify-content: space-between !important;
	background: white;
}

.commonClass {
	background: #eef1f7;
	border-radius: 20px;
	animation: shimmer 1s infinite;
	-webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
		100%;
}

@keyframes shimmer {
	100% {
		-webkit-mask-position: left;
	}
}
