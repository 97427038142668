.body {
	margin: 1rem 0;
	background: white;
	border-radius: 20px;
	position: relative;
	padding: 2rem 0;
	min-height: calc(100vh - 17rem);
	/* max-height: 100vh; */
	overflow: auto;
}

.grid {
	background-color: white;
	border-radius: 8;
	padding: 2rem;
	text-align: center;
}

.heading {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 0 1.5rem 0 2rem;
}

.rightSide {
	display: flex;
	height: auto;
}

.smallDeviceBtn {
	display: none !important;
	width: 25rem;
}

.outer {
	height: 33rem;
}

.inner {
	position: absolute; /* set the position property to absolute */
	display: flex;
	align-items: center;
	flex-direction: column !important;

	top: 50%; /* set the top property to 50% */
	left: 50%; /* set the left property to 50% */
	transform: translate(
		-50%,
		-50%
	); /* use the transform property to center the div */
}

@media screen and (max-width: 900px) {
	.smallDeviceBtn {
		display: block !important;
	}
	.bottomGrid {
		position: fixed;
		bottom: 2rem;
		left: 50%;
		transform: translateX(-50%);
	}
}
