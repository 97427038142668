::-webkit-scrollbar {
	display: none;
}

.pdp-outer-layout {
	padding: 0px 3%;
}

.pdp_image_carousel {
	padding-top: 1.5em;
}

.pdp_internal_layout {
	padding: 12px 40px 0 20px;
	background: #fff;
	border-radius: 8px;
}
.pdp_details_section {
	margin-left: 2.5em !important;
	max-height: 80vh;
	overflow-y: scroll;
	scrollbar-width: 0;
}

.pdp_horizontal_scroll {
	display: flex;
	flex-flow: row;
	overflow-x: scroll;
	scrollbar-width: thin;
}

.pdp_horizontal_scroll::-webkit-scrollbar {
	display: block;
	width: 6px;
	height: 6px;
}

.pdp_horizontal_scroll::-webkit-scrollbar-thumb {
	background-color: #b5bbc4;
	border-radius: 4px;
}

.pdp_horizontal_scroll::-webkit-scrollbar-track {
	background-color: #f2f4f7;
}

.pdp_horizontal_scroll {
	padding-bottom: 20px;
}

.pdp_header_arrow {
	border-radius: 50%;
	border: 0.5px solid #000;
	background: #f7f8fa;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
}

.pdp_left_arrow {
	margin-right: 1em;
}

.pdp_pointer {
	cursor: pointer;
}

.pdp_block_pointer {
	cursor: not-allowed;
}

.pdp_customise_input {
	margin: 16px 0;
}

.pdp_customise_footer {
	position: fixed;
	bottom: 2em;
	width: 27%;
}

.pdp_pricelist {
	padding: 0.5em 1em;
	border: 1px solid #e0e0e0;
	border-radius: 8px;
	margin: 0.6em 0;
	cursor: pointer;
}

.pdp_read_or_hide {
	color: rgb(192, 192, 192);
	cursor: pointer;
}

.pdp_accordion_content:first-child {
	margin-top: -1em;
}

.control-dots {
	margin-bottom: 0.5em !important;
}

.carousel.carousel-slider {
	height: 100% !important;
}
.carousel .thumb.selected,
.carousel .thumb:hover {
	border: 1px solid #333 !important;
	border-radius: 10px;
}

.carousel .thumb {
	border: none;
}

.carousel .carousel-status {
	padding: 1.5em !important;
}

@media only screen and (max-width: 900px) {
	.pdp-outer-layout {
		padding: 0px 12px;
	}
	.pdp_internal_layout {
		padding: 1.5em;
		background: #fff;
		border-radius: 8px;
	}
	.pdp_details_section {
		margin: 0px !important;
		padding: 0px !important;
		max-height: 100%;
	}
	.pdp_image_carousel {
		padding-left: 0 !important;
	}
}

@media only screen and (min-width: 1200px) {
	.pdp-outer-layout {
		padding: 0px 5%;
	}
	.pdp_internal_layout {
		width: 1200px;
	}
	.pdp_image_carousel {
		padding-left: 1em !important;
	}
}

@media only screen and (min-width: 1636px) {
	.pdp_customise_footer {
		position: fixed;
		bottom: 2em;
		width: 20vw;
	}
	.carousel .thumbs-wrapper {
		width: 97% !important;
	}
	.pdp-outer-layout {
		padding: 0px 10%;
	}
}
