.addTaxesBody {
	padding: 1.5rem;
	position: relative;
	height: 100vh;
	background-color: white;
	display: flex !important;
	height: 100vh;
}

.body {
	margin: 2rem 0;
}

.field {
	margin-bottom: 1.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flexJustifiedCenterWithMargin {
	display: flex;
	justify-content: space-between;
	margin: 0.5rem;
}
.flexJustifiedCenterWithMargin {
	display: flex;
	justify-content: space-between;
	margin: 0.5rem;
}
.flexJustifiedCenterWithMargin {
	display: flex;
	justify-content: space-between;
	margin: 0.5rem;
}

.bottomFixed {
	position: sticky;
	background: white;
	bottom: 0;
	height: 8.75rem;
}

.top {
	overflow: auto;
	height: calc(100vh - 11.25rem);
	padding-bottom: 1rem;
}

.subTotal {
	border-top: 1px dashed rgba(0, 0, 0, 0.4);
	display: flex;
	align-items: center;
	padding: 0.8rem 0;
	margin-bottom: 1.5rem;
	justify-content: space-between;
	border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
}
