.ag-root-wrapper {
	border: none !important;
	border-radius: 12px;
	font-family: Satoshi !important;
}

.ag-center-cols-container > div {
	background-color: white !important;
}

.editable-cell {
	background-color: #fafafa !important;
}

.ag-row-hover {
	background: white !important;
}

.ag-row-hover:not(.ag-full-width-row)::before,
.ag-row-hover.ag-full-width-row.ag-row-group::before {
	background-color: white !important;
}

.tooltip {
	position: relative;
	display: inline-block;
}

.tooltip .tooltiptext {
	visibility: hidden;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;

	/* Position the tooltip */
	position: absolute;
	z-index: 1;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
}
.tooltiptext {
	position: absolute;
	top: 100%;
	min-width: 120px;
	font-size: 12px;
	font-weight: 700;
	right: 50%;
	transform: translate(50%, -50%);
}

.tooltip-bottom::after {
	content: '';
	position: absolute;
	bottom: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent #555 transparent;
}

#webpack-dev-server-client-overlay {
	display: none !important;
}
