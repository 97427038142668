.empty_state_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #fff;
	width: 100%;
	height: 70vh;
	border-radius: 12px;
}

.horizontal_filters {
}
/* 
.search_input {
	width: 200px;
} */

.filter_icon {
	display: block;
	/* margin-right: 10px; */
}
.filter_sort {
	display: flex;
	justify-content: flex-end;
}

@media only screen and (max-width: 900px) {
	.horizontal_filters {
		display: none;
	}
	.filter_icon {
		margin-left: auto !important;
		margin-right: 10px !important;
	}
	/* .filter_sort {
		margin-right: 2em !important;
	} */
}

@media only screen and (min-width: 1500px) {
	/* .horizontal_filters {
		max-width: 900px;
		margin-left: -3em !important;
	} */
	.filter_icon {
		margin-left: auto !important;
		margin-right: 10px !important;
	}
	/* .filter_sort {
		margin-right: 5em !important;
	} */
}
